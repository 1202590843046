

export function isset(obj){
	if(obj == undefined || obj == null ){
		return false;
	}
	return true;
}

export function get(obj, def){
	if(isset(obj)){
		return obj;
	}else{
		return def;
	}
}

export function output_secure(string){
	if(!string) return '';
	return string.replace('/<\/?script>?/gmi','');
}

export function addClass(element, className) {
	
    var classes = element.className.split(" ");
    var i = classes.indexOf(className);
    if (i < 0){
        
        classes.push(className);
    }
    element.className = classes.join(" "); 

}

export function rmClass(element, className) {


    var classes = element.className.split(" ");
    var i = classes.indexOf(className);
    if (i >= 0) {
        classes.splice(i, 1);
    }
    element.className = classes.join(" "); 

}


export function sleep(min, max) { 
    let random =Math.floor(Math.random() * (+max - +min)) + +min;
	return new Promise(resolve => setTimeout(resolve, random));
}


export function clearNumber(string){
	if(!string) return 0;
	return string.toString().replace(/[^\d\-\.]/g,'');
}

export function formatNumber(num) {
	if(!num) return 0;
	num = num.toString().replace(/[^\d\-\.]/g,'');
	if(num.includes('.')){
	return num.replace(/(\d)(?=(\d{3})+(?=\.))/g, '$1,')
	}else{
	return num.replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
	}
}

export function makeId(length=16){
	return Array.from({ length }, () => Math.floor(Math.random() * 36).toString(36)).join('') + Date.now().toString(36);
}

export function firstUpCase(string) {
	if(typeof(string) != 'string') return string
	return string.charAt(0).toLocaleUpperCase() + string.slice(1);
}

export function isMobile(){
	return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
}

